import React, { FC } from "react";
import classNames from "classnames";

interface SeparatorFormProps {
  className?: string;
}

const SeparatorForm: FC<SeparatorFormProps> = ({ className }) => {
  return (
    <div className={classNames("block py-5", className)}>
      <div className="border-t border-gray-200"></div>
    </div>
  );
};

export default SeparatorForm;
