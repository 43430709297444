import { FC } from "react";
import classNames from "classnames";
import Link from "next/link";

import useIsMobileWebView from "@hooks/use-is-mobile-webview";
import { SanitizedUserType } from "@lib/shared-types";

interface Props {
  coach?: SanitizedUserType;
  landingPage?: boolean;
}

const ClientFooterLayout: FC<Props> = ({ coach, landingPage }) => {
  const mobileSession = useIsMobileWebView();
  const hasCoachWhiteLabel = coach?.featureNames?.whiteLabel;

  const shouldBeHidden = mobileSession || !coach || hasCoachWhiteLabel;

  return (
    <div
      className={classNames(
        "w-full font-roboto",
        shouldBeHidden && "hidden",
        "print:block"
      )}
    >
      <div
        className={`${
          landingPage ? "md:max-w-screen-lg" : "md:max-w-screen-md md:pr-6"
        } mx-auto pb-10 flex justify-center md:justify-end`}
      >
        <Link href="/" target="_blank">
          <div
            className={classNames(
              "text-foreground/60 bg-foreground/7",
              "flex items-center p-2 text-sm rounded-full pr-3"
            )}
          >
            <div className="flex items-center mr-2">
              <img src="/images/logo.svg" alt="Logo" />
            </div>
            <div>Built with Practice</div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default ClientFooterLayout;
