import classNames from "classnames";

import ArrowIcon from "@components/Icons/ArrowIcon";
import LoopIcon from "@components/Icons/LoopIcon";

type TodaysChargeVariants = "default" | "internal";

interface TodaysChargeProps {
  variant?: TodaysChargeVariants;
  className?: string;
  total: string | number;
  description?: string;
  taxesIncluded?: boolean;
  isTrial?: boolean;
}

type StyleType = Record<
  TodaysChargeVariants,
  Record<string, string | undefined>
>;

const styles: StyleType = {
  default: {
    container: "bg-accent/25 text-foreground",
    iconContainer: "bg-accent/50",
    text: "button-text",
    taxes: "button-text/80",
  },
  internal: {
    container: "bg-action-950 text-action-500",
    iconContainer: "bg-action-800",
    text: "text-action-500",
    taxes: "text-action-500/80",
  },
};

export const TodaysCharge: React.FC<TodaysChargeProps> = ({
  className,
  variant = "default",
  total,
  description,
  taxesIncluded,
  isTrial,
}) => {
  const style = styles[variant];
  return (
    <div
      className={classNames(
        "rounded-lg p-4 flex items-center",
        style?.container,
        className
      )}
    >
      <div
        className={classNames(
          "w-6 h-6 rounded-full mr-2",
          style?.iconContainer
        )}
      >
        <ArrowIcon className={classNames("h-6 w-6 rotate-180", style?.text)} />
      </div>
      <div className="flex-1 mr-4">
        <h3 className={classNames("font-medium", style?.text)}>
          {isTrial ? "Charged at the end of trial" : "Today’s charge"}
        </h3>
        {description && (
          <p className={classNames("font-medium text-xs", style?.text)}>
            {description}
          </p>
        )}
      </div>
      <p className={classNames("font-medium text-right", style?.text)}>
        {total}
        {taxesIncluded && (
          <span className={classNames("text-xs block", style?.taxes)}>
            Taxes included
          </span>
        )}
      </p>
    </div>
  );
};

interface SubscriptionInformationProps {
  count?: number;
}

export const SubscriptionInformation: React.FC<
  SubscriptionInformationProps
> = ({ count }) => (
  <div className="bg-blue-700 text-foreground flex justify-between items-center p-4 rounded-xl">
    <div className="flex items-center text-foreground gap-2">
      <div className="bg-foreground text-background p-1 rounded-full">
        <LoopIcon />
      </div>
      <h3>This subscription ends after {count} payments.</h3>
    </div>
  </div>
);
