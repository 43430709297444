import { useMemo } from "react";

import { AccessType } from "@lib/data/schemas/account";

import useOrganizationAccount from "./use-organization-account";

type UseAccessTypeSignature = () => {
  /** @deprecated Use fine-grained permissions */
  accessType?: AccessType;
  loading: boolean;
  /** @deprecated Use fine-grained permissions */
  hasFullAccess: boolean;
  /** @deprecated Use fine-grained permissions */
  hasElevatedAccess: boolean;
  /** @deprecated Use fine-grained permissions */
  hasLimitedAccess: boolean;
  permissions?: {
    canViewAllContacts: boolean;
    canEditAllContacts: boolean;
  };
};

const useAccessType: UseAccessTypeSignature = () => {
  const { account, loading: loadingAccount } = useOrganizationAccount();

  const accessType = useMemo(() => {
    return account?.accessType;
  }, [account]);

  return {
    accessType,
    loading: loadingAccount,
    hasFullAccess: accessType === "full",
    hasElevatedAccess: accessType === "elevated" || accessType === "full",
    hasLimitedAccess: accessType !== "elevated" && accessType !== "full",
    permissions: account?.permissions,
  };
};

export default useAccessType;
