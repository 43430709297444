import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const ArrowIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5.93935 11.6465C5.74409 11.8417 5.74409 12.1583 5.93935 12.3536L10.182 16.5962C10.3773 16.7915 10.6938 16.7915 10.8891 16.5962L11.5962 15.8891C11.7915 15.6939 11.7915 15.3773 11.5962 15.182L9.41423 13H17.5C17.7762 13 18 12.7762 18 12.5V11.5C18 11.2239 17.7762 11 17.5 11H9.41423L11.5962 8.81805C11.7915 8.62278 11.7915 8.3062 11.5962 8.11094L10.8891 7.40383C10.6938 7.20857 10.3773 7.20857 10.182 7.40383L5.93935 11.6465Z" />
    </svg>
  );
};

export default ArrowIcon;
