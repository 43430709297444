import React from "react";
import { FieldValues } from "react-hook-form";
import classNames from "classnames";
import get from "lodash/get";

import { domainNameRegEx, subdomainNameRegEx } from "@lib/utils/domains";

import { ErrorMessage } from "@components/ErrorMessage";
import CloseIcon from "@components/Icons/CloseIcon";
import InfoIcon from "@components/Icons/InfoIcon";
import { Tooltip } from "@components/Tooltips/Tooltip";

import { TextFieldFormProps } from "./types";

// https://regexlib.com/REDetails.aspx?regexp_id=606
// ^(?!0,?\d) #don't allow leading zero if 1 or more unit (\d{1,3} # 1 to 3 digits (\,\d{1,3})* # if the is a comma it must be followed by 3 digits |(\d+)) # more than 3 digit with no comma separator (\.\d{2})?$ # option cents
const currencyRegex = /^(?!0,?\d)(\d{1,3}(,\d{1,3})*|(\d+))(\.\d{1,2})?$/;
const numberRegEx = /-?\d*\.?\d{1,2}/;
const emailRegEx = /^\s*[a-zA-Z0-9_.+'-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+\s*$/;
const linkRegEx =
  /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/;

export const inputStyle =
  "border-grey-900 shadow-none rounded-md hover:bg-grey-950 hover:border-grey-950 focus:outline-none focus:ring-0 focus:shadow-none focus:bg-white focus:border-action-700 transition duration-150 ease-in-out";

export const inputErrorStyle =
  "border-peach-600 text-red-900 focus:border-red-300 focus:ring-0";

const TextFieldForm = function <T extends FieldValues>({
  inputClassName = "",
  containerClassName,
  label,
  type,
  name,
  placeholder,
  register,
  required,
  errors,
  errorMessage,
  isCurrency,
  isCurrencyErrorMessage,
  customValidation,
  onInputChange,
  isEmail,
  isEmailErrorMessage,
  helper,
  helperClassName,
  helperChildClassName,
  disabled = false,
  helperPrefix = false,
  defaultValue,
  onRemove,
  rightComponent,
  otherInputProps,
  profileHelper = false,
  tooltip,
  contactFormHelper = false,
  isNumber,
  isNumberErrorMessage,
  isLink,
  isLinkErrorMessage,
  isDomainName,
  isDomainNameErrorMessage = "Invalid domain name",
  isSubdomainName,
  isSubdomainNameErrorMessage = "Invalid subdomain name",
  readOnly,
  min, // string
  max,
  ...others
}: TextFieldFormProps<T>) {
  const error = get(errors, name);
  const hasError = required && error;
  const containerClasses = classNames(containerClassName, {
    "flex flex-wrap items-stretch w-full mb-4 relative": helper,
  });
  const inputClasses = classNames(
    "mt-1 py-2 px-3 w-full",
    !hasError && inputStyle,
    hasError && inputErrorStyle,
    {
      "disabled:bg-grey-950 disabled:cursor-not-allowed disabled:border-grey-900":
        disabled,
      "shrink flex-grow flex-auto leading-normal flex-1 border h-10 border-grey-light rounded-md px-3 relative":
        helper,
      "rounded-r-none": (helper && !helperPrefix) || rightComponent,
      "rounded-l-none": helper && helperPrefix,
      "form-input block w-full border": !helper,
      "bg-grey-950 border-transparent focus:bg-grey-950 focus:border-transparent text-grey-800":
        readOnly,
    },
    inputClassName
  );

  const renderTooltip = tooltip && (
    <Tooltip
      className="inline-block ml-2 text-grey-500 hover:text-black-ink z-10"
      trigger={<InfoIcon />}
    >
      {tooltip}
    </Tooltip>
  );

  return (
    <div className={containerClasses} {...others}>
      {label && (
        <label
          htmlFor={label}
          className="flex items-center text-sm leading-5 text-grey-500 w-full"
        >
          <span className="flex-1">{label}</span>
          {renderTooltip}
        </label>
      )}

      <div className="flex flex-1 flex-col">
        <div className="relative flex flex-1">
          <input
            id={label}
            title={label}
            name={name}
            type={type}
            defaultValue={defaultValue}
            placeholder={placeholder}
            onChange={onInputChange}
            {...(register &&
              register(name, {
                validate: customValidation,
                required: { value: required, message: errorMessage },
                valueAsNumber: isNumber,
                pattern: {
                  value:
                    (isCurrency && currencyRegex) ||
                    (isEmail && emailRegEx) ||
                    (isNumber && numberRegEx) ||
                    (isLink && linkRegEx) ||
                    (isDomainName && domainNameRegEx) ||
                    (isSubdomainName && subdomainNameRegEx),
                  message:
                    isCurrencyErrorMessage ||
                    isEmailErrorMessage ||
                    isNumberErrorMessage ||
                    isLinkErrorMessage ||
                    isDomainNameErrorMessage ||
                    isSubdomainNameErrorMessage,
                },
              }))}
            className={inputClasses}
            style={{ WebkitAppearance: "none" }}
            disabled={disabled}
            readOnly={readOnly}
            min={min}
            max={max}
            {...otherInputProps}
          />

          <div
            hidden={!onRemove}
            onClick={onRemove}
            className="cursor-pointer z-1000 absolute right-2 top-1/2 transform -translate-y-1/2"
          >
            <CloseIcon />
          </div>

          {helper ? (
            <div
              className={classNames(
                "flex -mr-px mt-1",
                helperPrefix && "order-first",
                helperClassName
              )}
            >
              <span
                className={classNames(
                  "flex items-center leading-normal bg-grey-lighter border border-grey-light px-3 whitespace-nowrap text-grey-dark text-sm rounded-md",
                  profileHelper && "bg-grey-950",
                  contactFormHelper && "bg-grey-950 w-12",
                  helperPrefix
                    ? "rounded-r-none border-r-0 "
                    : "rounded-l-none border-l-0 ",
                  helperChildClassName
                )}
              >
                {helper}
              </span>
            </div>
          ) : null}
          {rightComponent && rightComponent}
        </div>

        {error && error.message ? (
          <ErrorMessage className="mt-2">{error.message}</ErrorMessage>
        ) : null}
      </div>
    </div>
  );
};

export default TextFieldForm;
