import { FC, ReactNode } from "react";
import classNames from "classnames";

import { AccountType } from "@lib/data/schemas/account";
import { SchedulerType } from "@lib/data/schemas/scheduler";
import { getSchedulerType } from "@lib/schedulers";

import Listbox, {
  ListboxOption,
  useSimpleSearchProps,
} from "@components/Listbox";
import TypeIllustration from "@components/TypeIllustration";

import ClientAvatar from "./Client/ClientAvatar";
import { ConditionalTeamsRenderer } from "./ConditionalTeamsRenderer";

type SchedulerItemProps = {
  title: string;
  duration: number;
  icon: string;
  type?: string;
  account?: AccountType;
  showIcon?: boolean;
};

export const SchedulerItem: FC<SchedulerItemProps> = ({
  icon,
  title,
  duration,
  type,
  account,
  showIcon = false,
}) => {
  const defaultContent = <TypeIllustration slug={icon} />;
  return (
    <div className="flex justify-between items-center overflow-hidden">
      <div className="flex items-center overflow-hidden">
        {account && !showIcon ? (
          <ConditionalTeamsRenderer defaultContent={defaultContent}>
            <ClientAvatar client={account} />
          </ConditionalTeamsRenderer>
        ) : (
          defaultContent
        )}
        <div className="pl-4 overflow-hidden">
          <div className="truncate">{title}</div>
          <div className="text-sm text-grey-500">
            {duration} minutes
            {type && ` • ${type}`}
          </div>
        </div>
      </div>
    </div>
  );
};

interface SchedulerPickerProps {
  className?: string;
  schedulers: SchedulerType[];
  onSchedulerChange: (value: any) => void;
  selectedScheduler?: SchedulerType;
  label?: string | null;
  showIcon?: boolean;
  showSchedulerType?: boolean;
  customPlaceholder?: ReactNode;
  customMenuClassNames?: string;
}

const SchedulerPicker: FC<SchedulerPickerProps> = ({
  className,
  schedulers: allSchedulers,
  onSchedulerChange,
  selectedScheduler,
  label = "Scheduler",
  showIcon = false,
  showSchedulerType = false,
  customPlaceholder,
  customMenuClassNames,
}) => {
  const { filteredOptions: schedulers, searchProps } = useSimpleSearchProps(
    allSchedulers,
    "Search schedulers..."
  );
  return (
    <div className={classNames("mt-4", className)} id="scheduler-picker">
      <Listbox
        searchProps={searchProps}
        customListboxClassNames="!shadow-none"
        onChange={(scheduler: SchedulerType) => onSchedulerChange(scheduler)}
        label={label}
        placeholder={
          (selectedScheduler &&
            (showIcon ? (
              <SchedulerItem {...selectedScheduler} />
            ) : (
              selectedScheduler.title
            ))) ||
          (customPlaceholder ?? "Select a scheduler")
        }
        value={selectedScheduler}
        customAddButton={!!customPlaceholder}
        customMenuWrapperClassNames="bg-white"
        customMenuClassNames={customMenuClassNames}
      >
        {schedulers?.map((scheduler, index) => {
          const { title, icon, duration, account }: SchedulerItemProps =
            scheduler;
          const type = showSchedulerType
            ? getSchedulerType(scheduler)
            : undefined;
          return (
            <div key={index}>
              <ListboxOption value={scheduler} primaryText={title}>
                <SchedulerItem
                  title={title}
                  icon={icon}
                  duration={duration}
                  type={type}
                  account={account}
                  showIcon={showIcon}
                />
              </ListboxOption>
            </div>
          );
        })}
      </Listbox>
    </div>
  );
};

export default SchedulerPicker;
