import React, { FC } from "react";
import classNames from "classnames";

import { LabelProps } from "./types";

const Label: FC<LabelProps> = ({ htmlFor, children, className }) => (
  <label
    htmlFor={htmlFor}
    className={classNames("block text-sm leading-5 text-grey-500", className)}
  >
    {children}
  </label>
);

export default Label;
