import React, { FC } from "react";
import { DeepMap, FieldErrors, FieldValues } from "react-hook-form";
import classNames from "classnames";
import get from "lodash/get";

import { ErrorMessage } from "@components/ErrorMessage";

import { InputErrorType } from "./types";

/** retrieve the error for the current field */
export const useInputError = (
  errors: DeepMap<FieldValues, FieldErrors>,
  name: string
): InputErrorType => get(errors, name);

interface InputErrorProps {
  error: InputErrorType;
  className?: string;
}

export const InputError: FC<InputErrorProps> = ({ error, className }) => {
  return error && error.message ? (
    <ErrorMessage className={classNames("mt-2", className)}>
      {error.message}
    </ErrorMessage>
  ) : null;
};
