import { getTier } from "api-services/definitions/authorization";
import { useApiGet } from "api-services/endpoints";

import { useAuth } from "@contexts/auth";

export const useGetTier = () => {
  const { oid } = useAuth();

  const { data: tier } = useApiGet(
    oid ? getTier : undefined,
    { orgId: oid! },
    undefined,
    { revalidateOnFocus: false, dedupingInterval: 1000000 }
  );

  return tier;
};
