import React, { createContext } from "react";

export interface FlowContextType {
  currentStep: number;
  nextStep: (query: Record<string, unknown>, forceReload?: boolean) => void;
  previousStep: (query: Record<string, unknown>, forceReload?: boolean) => void;
  sharedData: Record<string, unknown>;
  setSharedData: (data: Record<string, unknown>) => void;
  timeZone?: string;
  setTimeZone: (value: string) => void;
}

export const FlowContext = createContext({ currentStep: 0 } as FlowContextType);

interface FlowProviderProps {
  length: number;
  sharedData: Record<string, unknown>;
  setSharedData: (data: Record<string, unknown>) => void;
  timeZone?: string;
}

export const FlowProvider: React.FC<FlowProviderProps> = ({
  children,
  length,
  sharedData,
  setSharedData,
}) => {
  const currentStep = Number(sharedData?.step) || 0;

  const nextStep = (query?: Record<string, unknown>) => {
    const potentialNextStep = currentStep + 1;
    const newNextStep =
      potentialNextStep >= length ? currentStep : potentialNextStep;
    setSharedData({ ...sharedData, ...query, step: newNextStep });
  };

  const previousStep = (
    query?: Record<string, unknown>,
    updateSharedData: boolean = true
  ) => {
    const potentialPreviousStep = currentStep - 1;
    const newPreviousStep =
      potentialPreviousStep < 0 ? currentStep : potentialPreviousStep;

    setSharedData({
      ...(updateSharedData ? sharedData : {}),
      ...query,
      step: newPreviousStep,
    });
  };

  const setTimeZone = (value: string) => {
    setSharedData({ ...sharedData, timeZone: value });
  };

  return (
    <FlowContext.Provider
      value={{
        currentStep,
        nextStep,
        previousStep,
        sharedData,
        setSharedData,
        timeZone: sharedData?.timeZone as string,
        setTimeZone,
      }}
    >
      {children}
    </FlowContext.Provider>
  );
};
