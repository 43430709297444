const plugin = require("tailwindcss/plugin");
const defaultTheme = require("tailwindcss/defaultTheme");
const colors = require("tailwindcss/colors");

const capitalizeFirst = plugin(function ({ addUtilities }) {
  const newUtilities = {
    ".capitalize-first:first-letter": {
      textTransform: "uppercase",
    },
  };
  addUtilities(newUtilities);
});

const labelChecked = plugin(({ addVariant, e }) => {
  addVariant("label-checked", ({ modifySelectors, separator }) => {
    modifySelectors(({ className }) => {
      const eClassName = e(`label-checked${separator}${className}`);
      const selector = 'input[type="radio"]';
      return `${selector}:checked ~ .${eClassName}`;
    });
  });
});

function withOpacity(variableName) {
  return ({ opacityValue }) => {
    if (opacityValue !== undefined) {
      return `rgba(var(${variableName}), ${opacityValue})`;
    }
    return `rgb(var(${variableName}))`;
  };
}

module.exports = {
  content: [
    "./components/**/*.{js,ts,jsx,tsx}",
    "./pages/**/*.{js,ts,jsx,tsx}",
    "./contexts/**/*.{js,ts,jsx,tsx}",
  ],
  theme: {
    screens: {
      xs: "400px",
      ...defaultTheme.screens,
    },
    extend: {
      backgroundColor: {
        background: withOpacity("--color-background"),
        foreground: withOpacity("--color-foreground"),
        accent: withOpacity("--color-accent"),
      },
      borderColor: {
        background: withOpacity("--color-background"),
        foreground: withOpacity("--color-foreground"),
      },
      divide: {
        foreground: withOpacity("--color-foreground"),
      },
      backgroundImage: () => ({
        "upgrade-pro-card-custom-branding":
          "url('/images/asset_upsell-pro_custombranding_card@3x.png')",
        "upgrade-pro-card-custom-domain":
          "url('/images/asset_upsell-pro_domain_card@3x.png')",
        "upgrade-pro-card-digital-signature":
          "url('/images/asset_upsell-pro_digitalsignature_card@3x.png')",
        "upgrade-pro-card-zapier":
          "url('/images/asset_upsell-pro_zapier_card@3x.png')",
        "upgrade-pro-modal-custom-branding":
          "url('/images/asset_upsell-pro_custombranding_modal@3x.png')",
        "upgrade-pro-modal-custom-domain":
          "url('/images/asset_upsell-pro_domain_modal@3x.png')",
        "upgrade-pro-modal-digital-signature":
          "url('/images/asset_upsell-pro_digitalsignature_modal@3x.png')",
        "upgrade-pro-modal-zapier":
          "url('/images/asset_upsell-pro_zapier_modal@3x.png')",
        "hero-sphere": "url('/images/homepage/hero_sphere.png')",
        "hero-sphere-mobile": "url('/images/homepage/hero_sphere_mobile.png')",
        "practice-logo": "url('/images/homepage/logo-background.svg')",
        waves: "url('/images/waves.svg')",
      }),
      backgroundOpacity: {
        5: "0.05",
        7: "0.07",
      },
      borderRadius: {
        xl: "0.75rem",
        "2xl": "1rem",
        "3xl": "1.5rem",
      },
      boxShadow: {
        "thin-action-700": "0 0 0 1px #F1C27D",
        "thin-black-b": "0px 1px 1px rgba(0, 0, 0, 0.07)",
        "thin-black-t": "0px -1px 1px rgba(0, 0, 0, 0.07)",
        "equal-20": "0 0 20px rgba(0, 0, 0, 0.15)",
        "equal-24": "0px 4px 24px rgba(0, 0, 0, 0.1)",
        "elevation-50":
          "0px 8px 48px 0px rgba(4, 5, 6, 0.05), 0px 4px 8px 0px rgba(4, 5, 6, 0.03), 0px 0px 1px 0px rgba(4, 5, 6, 0.32)",
        "elevation-100":
          "0px 8px 48px 0px rgba(4, 5, 6, 0.10), 0px 4px 8px 0px rgba(4, 5, 6, 0.06), 0px 0px 1px 0px rgba(4, 5, 6, 0.32)",
        thin: "0px 2px 6px rgba(0, 0, 0, 0.2)",
      },
      colors: {
        white: "#ffffff",
        red: colors.red,
        current: "currentColor",
        "accent-1": "#ccc",
        "black-ink": "#040506",
        "grey-EEE": "#EEEEEE",
        "grey-100": "#18181B",
        "grey-150": "#242526",
        "grey-250": "#3A3C43",
        "grey-300": "#4C4C4C",
        "grey-500": "#8A898E",
        "grey-600": "#929292",
        "grey-800": "#CCCCCC",
        "grey-900": "#E6E6E6",
        "grey-950": "#F7F7F7",
        "action-250": "#76410A",
        "action-300": "#8B5114",
        "action-400": "#A5631D",
        "action-500": "#CC8033",
        "action-600": "#E3A75F",
        "action-700": "#F1C27D",
        "action-800": "#FAD89E",
        "action-900": "#FBEDD8",
        "action-950": "#FDF6EC",
        "blue-100": "#061632",
        "blue-150": "#021C4A",
        "blue-200": "#122F62",
        "blue-250": "#002566",
        "blue-300": "#00338C",
        "blue-350": "#003FAD",
        "blue-400": "#003EDF",
        "blue-450": "#05338c",
        "blue-500": "#4C70AE",
        "blue-700": "#94ABD1",
        "blue-900": "#BFCCE2",
        "blue-950": "#DEE5F0",
        "green-100": "#033B0F",
        "green-200": "#264F2D",
        "green-300": "#4D664F",
        "green-500": "#6E8970",
        "green-600": "#87B590",
        "green-online-600": "#75BD7B",
        "green-800": "#C6D2C8",
        "green-900": "#E2E8E3",
        "peach-250": "#60202F",
        "peach-400": "#A2445A",
        "peach-500": "#C35560",
        "peach-600": "#E46767",
        "peach-800": "#F0ABAB",
        "peach-950": "#FCF0F0",
        "violet-300": "#571F99",
        "violet-900": "#E3CCFF",
        background: withOpacity("--color-background"),
        foreground: withOpacity("--color-foreground"),
        "button-text": withOpacity("--color-button-text"),
        "accent-90": withOpacity("--color-accent-90"),
        "accent-80": withOpacity("--color-accent-80"),
        accent: withOpacity("--color-accent"),
        error: withOpacity("--color-error"),
        stripe: "#635BFF",
      },
      fontFamily: {
        roboto: ["Roboto", "Helvetica", "Arial", "sans-serif"],
        crimson: ["Crimson Pro", "serif"],
        rubik: ["Rubik", "Helvetica", "Arial", "sans-serif"],
        robotoMono: ["Roboto Mono", "Helvetica", "Arial", "sans-serif"],
      },
      fontSize: {
        xs: "0.75rem",
        sm: "0.875rem",
        base: "1rem",
        lg: "1.125rem",
        xl: "1.25rem",
        "2xl": "1.5rem",
        "3xl": "1.875rem",
        "4xl": "2.25rem",
        "5xl": "3rem",
        "6xl": "4rem",
      },
      gridTemplateColumns: {
        "fill-120": "repeat(auto-fill, minmax(120px, 1fr))",
      },
      inset: {
        "-6": "-24px",
        "1/2": "50%",
      },
      keyframes: {
        fadeIn: { "0%": { opacity: 0 }, "100%": { opacity: 1 } },
      },
      maxWidth: {
        xxs: "16rem",
        xxxs: "12rem",
      },
      minWidth: {
        xxxxxs: "8rem",
      },
      opacity: {
        "02": "0.02",
        5: ".05",
        7: ".07",
        10: "0.1",
      },
      scale: {
        "-1": "-1",
      },
      screens: {
        "can-hover": { raw: "(hover: hover)" },
        print: { raw: "print" },
      },
      truncate: {
        lines: {
          2: "2",
          3: "3",
          4: "4",
        },
      },
      width: {
        "screen-90": "90vw",
        105: "26.25rem",
        10: "2.5rem",
        11: "2.75rem",
        44: "11rem",
      },
    },
  },
  plugins: [
    require("@tailwindcss/forms"),
    require("@tailwindcss/typography"),
    require("@tailwindcss/aspect-ratio"),
    require("tailwindcss-truncate-multiline")(),
    require("tailwind-scrollbar-hide"),
    require("tailwindcss-inner-border"),
    capitalizeFirst,
    labelChecked,
  ],
};
