import { isArray } from "lodash";

/**
 * This function is used to check if a value is selected in a multivalue field
 * @param multiValues the array of current values (flat)
 * @param currentValue the current value
 * @returns true or false
 */

export const formValueIsSelected = (
  multiValues: string[],
  currentValue: string | string[]
): boolean => {
  const isArrayValue = isArray(currentValue);
  if (isArrayValue) {
    const currentContainsValueNotInMultivalue = currentValue.find(
      (value: string) => !multiValues.includes(value)
    );
    return !currentContainsValueNotInMultivalue;
  }
  return false;
};
