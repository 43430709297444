export const domainNameRegEx =
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-zA-Z0-9]+([\-\.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,63}(:[0-9]{1,5})?(\/.*)?$/;

export const subdomainNameRegEx = /^[a-zA-Z0-9]+[a-zA-Z0-9-._]*[a-zA-Z0-9]+$/;

export const sanitizeDomainName = (domainName?: string): string => {
  if (!domainName) return "";
  return domainName
    .replace(/^(www\.|http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)/, "")
    .split("/")[0] // remove path eg. /home /index.php
    .toLowerCase();
};

export const sanitizeSubdomainName = (subdomainName?: string): string => {
  if (!subdomainName) return "";
  return subdomainName.toLowerCase();
};
